import { includes } from 'lodash-es';
import { FormFields } from '../typedefs';
import { DuctedDeliveryType, ExistingAcTonnage, LayoutType, NumExistingSystems, WholeHomeSystem } from './enums';

const SizingModeUtil = {
  showNumExistingSystemsSelector: (formData: Partial<FormFields>) => {
    const { layoutType, ductedDelivery } = formData;
    return (
      (layoutType === LayoutType.WHOLE_HOME || layoutType === LayoutType.REPLACE_EXISTING) &&
      ductedDelivery === DuctedDeliveryType.YES
    );
  },
  showExistingAcTonnageSelector: (formData: Partial<FormFields>) => {
    const { ductedDelivery, layoutType, wholeHomeSystem } = formData;
    return (
      (layoutType === LayoutType.WHOLE_HOME || layoutType === LayoutType.REPLACE_EXISTING) &&
      ductedDelivery === DuctedDeliveryType.YES &&
      wholeHomeSystem === WholeHomeSystem.YES
    );
  },
  isInReplacementMode: (formData: Partial<FormFields>) => {
    if (SizingModeUtil.showNumExistingSystemsSelector(formData)) {
      const { numExistingSystems } = formData;
      return (
        numExistingSystems !== undefined &&
        !includes([NumExistingSystems.UNKNOWN, NumExistingSystems.ONE], numExistingSystems)
      );
    }
    if (SizingModeUtil.showExistingAcTonnageSelector(formData)) {
      const { existingAcTonnage } = formData;
      return existingAcTonnage !== undefined && existingAcTonnage !== ExistingAcTonnage.UNKNOWN;
    }

    return false;
  },
};

export default SizingModeUtil;
