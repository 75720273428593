import { fromPairs, isEmpty, map } from 'lodash-es';
import { getHeatPumpDesignType, getProjectId, getProjectTotal } from '../redux/slice/projects';
import { type EquipmentRecommendationAPIPayload } from '../typedefs';
import { HeatPumpDesignType, SystemDifferentiator } from './enums';

function getDifferentiatedProjectId(
  projectList: EquipmentRecommendationAPIPayload[],
  getVal: (a: EquipmentRecommendationAPIPayload) => number,
): number | undefined {
  const filteredArr = projectList.slice().filter(getVal);
  if (isEmpty(filteredArr)) {
    // No elements meet the differentiation
    return undefined;
  }
  const sortedArr = filteredArr.sort((a, b) => getVal(a) - getVal(b));
  if (sortedArr.length === 1 || getVal(sortedArr[0]) !== getVal(sortedArr[1])) {
    return getProjectId(sortedArr[0]);
  }
  return undefined;
}

function addLabel(
  label: SystemDifferentiator,
  labelsById: { [projectId: string]: SystemDifferentiator[] },
  projectId?: number,
) {
  if (!projectId) {
    return;
  }
  labelsById[projectId].push(label);
}

const getUtilitySavings = (project: EquipmentRecommendationAPIPayload) => -project.utilitySavings;
const getNoiseLevel = (project: EquipmentRecommendationAPIPayload) =>
  project.systemConfiguration?.externalModel.noiseMin || 0;
const getColdClimate = (project: EquipmentRecommendationAPIPayload) => {
  const heatPumpDesignType = getHeatPumpDesignType(project);
  return heatPumpDesignType === HeatPumpDesignType.COLD_CLIMATE_HEAT_PUMP ? 1 : 0;
};
const getIsDualFuel = (project: EquipmentRecommendationAPIPayload) => {
  const heatPumpDesignType = getHeatPumpDesignType(project);
  return heatPumpDesignType === HeatPumpDesignType.HYBRID_HEAT_PUMP ||
    heatPumpDesignType === HeatPumpDesignType.HYBRID_HEAT_PUMP_AND_FURNACE
    ? 1
    : 0;
};
const getComfort = (project: EquipmentRecommendationAPIPayload) =>
  (project.systemConfiguration?.seer2 || project.systemConfiguration?.seer || 0) * -1;
const getCarbonSavings = (project: EquipmentRecommendationAPIPayload) => -project.carbonSavings;

const DifferentiatorUtil = {
  getLabelsByProjectId: (projectList: EquipmentRecommendationAPIPayload[]) => {
    const labelsById: { [projectId: string]: SystemDifferentiator[] } = fromPairs(
      map(projectList, (project) => [getProjectId(project), []]),
    );

    addLabel(SystemDifferentiator.UPFRONT_COST, labelsById, getDifferentiatedProjectId(projectList, getProjectTotal));
    addLabel(
      SystemDifferentiator.UTILTIY_SAVINGS,
      labelsById,
      getDifferentiatedProjectId(projectList, getUtilitySavings),
    );
    addLabel(SystemDifferentiator.NOISE_LEVEL, labelsById, getDifferentiatedProjectId(projectList, getNoiseLevel));
    addLabel(SystemDifferentiator.COLD_CLIMATE, labelsById, getDifferentiatedProjectId(projectList, getColdClimate));
    addLabel(SystemDifferentiator.DUAL_FUEL, labelsById, getDifferentiatedProjectId(projectList, getIsDualFuel));
    addLabel(SystemDifferentiator.MOST_COMFORTABLE, labelsById, getDifferentiatedProjectId(projectList, getComfort));
    addLabel(
      SystemDifferentiator.CARBON_SAVINGS,
      labelsById,
      getDifferentiatedProjectId(projectList, getCarbonSavings),
    );
    return labelsById;
  },
};

export default DifferentiatorUtil;
