import autoBind from 'auto-bind';
import type { FormErrors, FormFields, FormFieldsTouched } from '../../typedefs';
import { LayoutType } from '../enums';
import FormValidator from './formValidator';

export default class ServiceFormValidator extends FormValidator {
  constructor(form: FormFields, touched: FormFieldsTouched, layout?: LayoutType) {
    super(form, touched, layout);
    autoBind(this);
  }

  validate(): FormErrors {
    this.validateIntro();
    this.validateAddress();
    this.validateContactInfo();
    this.validateRepairDetails();
    return this.errors;
  }

  validateRepairDetails() {
    const { repairDetails } = this.form;
    if (!repairDetails && this.touched.repairDetails) {
      this.isRequired('repairDetails', 'Issue description');
    }
  }
}
