import autoBind from 'auto-bind';
import { FormErrors, RebateFields } from '../../typedefs';
import { DuctedDeliveryType, FurnaceType, LayoutType } from '../enums';
import AbstractValidator from './abstractValidator';

export default class RebateValidator extends AbstractValidator<RebateFields> {
  constructor(form: Partial<RebateFields>) {
    super(form);
    autoBind(this);
  }

  validate(): FormErrors {
    this.checkZip(this.form.zipCode);
    this.isEnumMember('layoutType', LayoutType);
    const { layoutType } = this.form;
    if (
      layoutType === LayoutType.MULTIPLE_ROOMS ||
      layoutType === LayoutType.WHOLE_HOME ||
      layoutType === LayoutType.REPLACE_EXISTING
    ) {
      this.isRequired('furnaceType', 'Furnace Type');
      this.isEnumMember('furnaceType', FurnaceType);
      if (layoutType === LayoutType.WHOLE_HOME || layoutType === LayoutType.REPLACE_EXISTING) {
        this.isRequired('ductedDelivery', 'Ducted Delivery');
        this.isEnumMember('ductedDelivery', DuctedDeliveryType);
      }
    }
    return this.errors;
  }
}
