import { getEquipmentLifetimeInMonths } from '../redux/slice/projects';
import type { EquipmentRecommendationAPIPayload, LineItem, Rebate } from '../typedefs';
import { LineItemType } from './enums';
import NumberUtil from './numberUtil';
import RebateUtil from './rebateUtil';

const PriceUtil = {
  evaluatePricingForProject({
    project,
    rebates,
    rebateTotal,
  }: {
    project: EquipmentRecommendationAPIPayload;
    rebates?: Rebate[];
    rebateTotal?: number;
  }) {
    const { total, utilitySavings } = project;
    const equipmentLifetimeInMonths = getEquipmentLifetimeInMonths(project);
    const effectiveRebateTotal = rebateTotal ? rebateTotal : RebateUtil.getRebateTotal(rebates);

    const priceAfterRebates = PriceUtil.getPriceAfterRebates({ price: total, totalRebates: effectiveRebateTotal });
    const monthlyUtilitySavings = PriceUtil.getMonthlySavings({
      utilitySavings,
      equipmentLifetimeInMonths,
    });

    return {
      priceAfterRebates,
      utilitySavings,
      monthlyUtilitySavings: NumberUtil.roundToSignificantDigits(monthlyUtilitySavings, 4),
    };
  },

  getPriceItemAmount(lineItem: LineItem) {
    return lineItem.quantity * lineItem.price;
  },

  getDescription(lineItem: LineItem) {
    const { type, quantity, description } = lineItem;
    if (!quantity || quantity === 1 || type === LineItemType.OTHER || type === LineItemType.LABOR) {
      return description;
    }
    return `${description} (x${quantity})`;
  },

  getPriceAfterRebates({ price, totalRebates }: { price?: number; totalRebates: number }) {
    return price && price - totalRebates;
  },

  getMonthlySavings({
    utilitySavings,
    equipmentLifetimeInMonths,
  }: {
    utilitySavings: number;
    equipmentLifetimeInMonths?: number;
  }) {
    return utilitySavings !== undefined && equipmentLifetimeInMonths
      ? utilitySavings / equipmentLifetimeInMonths
      : undefined;
  },
};

export default PriceUtil;
