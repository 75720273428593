import { deleteData, getData, postData, putData } from '../../redux/fetchService/baseFetch';
import {
  AuthenticationContext,
  EquipmentPhoto,
  NormalizedEntitiesAPIPayload,
  NormalizedSingleEntityAPIPayload,
  PhotoAPIPayload,
  PhotosAPIPayload,
} from '../../typedefs';
import NormalizationUtil from '../../utils/normalizationUtil';

export default class PhotosAPI {
  fetchPhotos = async (authContext: AuthenticationContext): Promise<PhotoAPIPayload[]> => {
    // This denormalization logic should be generalized.
    // Also, it could belong more in the thunks.ts file.
    const payload: NormalizedEntitiesAPIPayload<PhotosAPIPayload> = await getData('photos', authContext);
    return NormalizationUtil.denormalizeMultipleResults<PhotoAPIPayload, PhotosAPIPayload>(payload, 'photos');
  };

  fetchPhoto = async (id: string, authContext: AuthenticationContext): Promise<PhotoAPIPayload | undefined> => {
    // This denormalization logic should be generalized.
    // Also, it could belong more in the thunks.ts file.
    const payload: NormalizedSingleEntityAPIPayload<PhotosAPIPayload> = await getData(`photos/${id}`, authContext);
    return NormalizationUtil.denormalizeSingleResult<PhotoAPIPayload, PhotosAPIPayload>(payload, 'photos');
  };

  createPhoto = async (
    photo: EquipmentPhoto,
    authContext: AuthenticationContext,
  ): Promise<PhotoAPIPayload | undefined> => {
    // This denormalization logic should be generalized.
    // Also, it could belong more in the thunks.ts file.
    const payload: NormalizedSingleEntityAPIPayload<PhotosAPIPayload> = await postData('photos', photo, authContext);
    return NormalizationUtil.denormalizeSingleResult<PhotoAPIPayload, PhotosAPIPayload>(payload, 'photos');
  };

  updatePhoto = async (
    id: string,
    photo: EquipmentPhoto,
    authContext: AuthenticationContext,
  ): Promise<PhotoAPIPayload | undefined> => {
    // This denormalization logic should be generalized.
    // Also, it could belong more in the thunks.ts file.
    const payload: NormalizedSingleEntityAPIPayload<PhotosAPIPayload> = await putData(
      `photos/${id}`,
      photo,
      authContext,
    );
    return NormalizationUtil.denormalizeSingleResult<PhotoAPIPayload, PhotosAPIPayload>(payload, 'photos');
  };

  deletePhoto = async (id: string, authContext: AuthenticationContext): Promise<void> => {
    return deleteData(`photos/${id}`, authContext);
  };
}
