import autoBind from 'auto-bind';
import { FormErrors, PropertyDataFields } from '../../typedefs';
import AbstractValidator from './abstractValidator';

export default class PropertyDataValidator extends AbstractValidator<PropertyDataFields> {
  constructor(form: Partial<PropertyDataFields>) {
    super(form);
    autoBind(this);
  }

  validate(): FormErrors {
    const { zipCode } = this.form;
    this.isRequired('zipCode', 'zip code');
    this.checkZip(zipCode);
    this.isRequired('streetAddress', 'address');
    this.length('streetAddress', 'address', 4);
    return this.errors;
  }
}
