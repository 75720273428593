import autoBind from 'auto-bind';
import { filter, includes, isArray, isEmpty, isUndefined, join, upperFirst } from 'lodash-es';
import isValidEmail from 'validator/lib/isEmail';
import type { FormErrors } from '../../typedefs';
import NumberUtil from '../numberUtil';
import StringUtil from '../stringUtil';

export default abstract class AbstractValidator<Form extends { zipCode: string }> {
  errors: FormErrors;
  form: Partial<Form>;

  constructor(form: Partial<Form>) {
    this.form = form;
    this.errors = { generalErrors: [], fieldErrors: {}, serverFieldErrors: {} };
    autoBind(this);
  }

  validate(): FormErrors {
    throw new Error('Not implemented');
  }

  toNumber(str?: string) {
    const num = NumberUtil.toInteger(str);
    if (!num) {
      return 0;
    }
    return Math.max(num, 0);
  }

  invalidValueMessage(field: keyof Form, label: string) {
    return this.createError(field, `${upperFirst(label)} has an invalid value!`);
  }

  createGeneralError(message: string) {
    this.errors.generalErrors.push(message);
  }

  createError(field: keyof Form, message: string) {
    this.errors.fieldErrors[field as string] = this.errors.fieldErrors[field as string] || [];
    this.errors.fieldErrors[field as string].push(message);
  }

  checkZip(zipCode?: string) {
    if (!zipCode || zipCode.length !== 5 || isNaN(Number(zipCode))) {
      this.createError('zipCode', 'Invalid Zip Code');
    }
  }

  isRequired(field: keyof Form, label: string) {
    const value = this.form[field] as any;
    if (isUndefined(value) || value === '') {
      this.createError(field, `${upperFirst(label)} is required!`);
    }
  }

  isEmpty(field: keyof Form, label: string) {
    if (this.form[field]) {
      this.createError(field, `${upperFirst(label)} cannot be updated!`);
    }
  }

  isValidOption(field: keyof Form, label: string, options: Array<any>) {
    if (options.indexOf(this.form[field]) === -1) {
      this.createError(field, `${upperFirst(label)} must be a value from ${join(options, ', ')}`);
    }
  }

  isOfType(field: keyof Form, label: string, type: 'string' | 'number') {
    if (typeof this.form[field] !== type) {
      this.createError(field, `${upperFirst(label)} must be a ${type}`);
    }
  }

  isArrayOfType(field: keyof Form, label: string, type: 'string' | 'number') {
    const arr = this.form[field];
    if (!isArray(arr)) {
      this.createError(field, `${upperFirst(label)} must be an array`);
    } else {
      const invalidTypes = filter(arr, (item) => typeof item !== type);
      if (!isEmpty(invalidTypes)) {
        this.createError(field, `${upperFirst(label)} must only contain ${type}s`);
      }
    }
  }

  length(field: keyof Form, label: string, min?: number, max?: number) {
    const value = this.form[field];
    if (typeof value === 'string') {
      if (min && value.length < min) {
        this.createError(field, `${upperFirst(label)} must be at least ${min.toLocaleString()} letters!`);
      }
      if (max && value.length > max) {
        this.createError(field, `${upperFirst(label)} must be ${max.toLocaleString()} letters or less!`);
      }
    } else if (isArray(value)) {
      if (min && value.length < min) {
        this.createError(field, `${upperFirst(label)} must have at least ${min.toLocaleString()} items!`);
      }
      if (max && value.length > max) {
        this.createError(field, `${upperFirst(label)} must have ${max.toLocaleString()} items or less!`);
      }
    } else if (typeof value !== 'undefined') {
      this.createError(field, `${upperFirst(label)} has an invalid value!`);
    }
  }

  range(field: keyof Form, label: string, min?: number, max?: number, comma: boolean = true) {
    const value = Number(this.form[field]);
    if (typeof value === 'number') {
      if (typeof min !== 'undefined' && value < min) {
        this.createError(field, `${upperFirst(label)} must be at least ${comma ? min.toLocaleString() : min}!`);
      }
      if (typeof max !== 'undefined' && value > max) {
        this.createError(field, `${upperFirst(label)} must be ${comma ? max.toLocaleString() : max} or less!`);
      }
    } else if (typeof value !== 'undefined') {
      this.invalidValueMessage(field, label);
    }
  }

  isEmail(field: keyof Form) {
    const value = this.form[field];
    if (typeof value !== 'string') {
      this.createError(field, 'Invalid email!');
    } else if (!isValidEmail(value)) {
      this.createError(field, 'Invalid email!');
    }
  }

  isPhone(field: keyof Form) {
    const value = this.form[field];
    if (typeof value !== 'string') {
      this.createError(field, 'Invalid phone!');
    } else if (!StringUtil.isPhone(value)) {
      this.createError(field, 'Invalid phone!');
    }
  }

  isSame(field: keyof Form, label: string, fieldToMatch: keyof Form, matchLabel: string) {
    if (this.form[field] !== this.form[fieldToMatch]) {
      this.createError(field, `${upperFirst(label)} does not match ${matchLabel}!`);
    }
  }

  isEnumMember(field: keyof Form, enumType: any): void {
    const value = this.form[field];
    if (
      typeof value !== 'undefined' &&
      ((typeof value !== 'number' && typeof value !== 'string') ||
        (typeof value === 'number' && !(value in enumType)) ||
        (typeof value === 'string' && !includes(Object.values(enumType), value)))
    ) {
      this.createError(field, `${value} is not a valid type enumeration.`);
    }
  }

  hasErrors() {
    return !isEmpty(this.errors.fieldErrors) || !isEmpty(this.errors.generalErrors);
  }
}
