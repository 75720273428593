import { get } from 'lodash-es';
import ErrorUtil from './errorUtil';

// Required by google api scripts. We ideally want a noop action
const CALLBACK_FUNC = 'console.log';

const isGMapsLoaded = (): boolean => {
  const AutocompleteClass = get(globalThis, 'google.maps.places.Autocomplete');
  return !!AutocompleteClass;
};

const loadGMaps = async (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (isGMapsLoaded()) {
      resolve(true);
      return;
    }

    const id = 'googleMaps';
    const existingScript = document.getElementById(id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_MAPS_API_KEY}&loading=async&libraries=places&callback=${CALLBACK_FUNC}`;
      script.id = id;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = (e: string | Event) => {
        ErrorUtil.logError(new Error(`Google maps script failed to load! (msg: ${e.toString()})`));
        resolve(false);
      };
    }

    // fall back, may not be needed because of isGMapsLoaded() check above.
    if (existingScript) resolve(true);
  });
};

const mapsUtil = {
  loadGMaps,
  isGMapsLoaded,
};

export default mapsUtil;
