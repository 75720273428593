import { difference, filter, some } from 'lodash-es';
import stripDiacritics from './stripDiacritics';

type MatchOptions = {
  useDiacritics?: boolean;
  caseSensitive?: boolean;
};

type FilterOptions<T> = MatchOptions & {
  filterFields?: (keyof T)[];
  itemTransformer?: (item: T) => string;
};

function isTextMatch(target: string, search: string, options: MatchOptions): boolean {
  let targetStr = target || '';
  let searchStr = search || '';

  if (!options.caseSensitive) {
    targetStr = targetStr.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  if (!options.useDiacritics) {
    targetStr = stripDiacritics(targetStr);
    searchStr = stripDiacritics(searchStr);
  }

  return targetStr.indexOf(searchStr) !== -1;
}

function isItemMatch<T>(item: T, filterText: string, options: FilterOptions<T>): boolean {
  const { filterFields, itemTransformer } = options;

  if (itemTransformer) {
    return isTextMatch(itemTransformer(item), filterText, options);
  }

  if (filterFields === undefined) {
    // This should be impossible, but tsc requires it to use filterFields below.
    return false;
  }

  return some(filterFields, (field) => {
    const rawValue = item[field] || '';
    return isTextMatch(String(rawValue), filterText, options);
  });
}

function filterListByString<T>(list: T[], filterText: string, options: FilterOptions<T>): T[] {
  if (!filterText) return list;

  const { filterFields, itemTransformer } = options;

  if (filterFields === undefined && itemTransformer === undefined) {
    throw new Error('Either filterFields or itemTransformer must be supplied.');
  }

  return filter(list, (item) => {
    return isItemMatch<T>(item, filterText, options);
  });
}

function filterListUndefined<T>(list: (T | undefined)[]): T[] {
  return filter(list, (item) => item !== undefined) as T[];
}

function splitListByFilterMatch<T>(
  list: T[],
  predicate: (value: T, index: number) => boolean,
): { matches: T[]; nonmatches: T[] } {
  const matches = filter(list, predicate);
  const nonmatches = difference(list, matches);
  return { matches, nonmatches };
}

const FilterUtil = {
  filterListByString,
  filterListUndefined,
  splitListByFilterMatch,
};

export default FilterUtil;
